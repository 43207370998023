import React, { useEffect, useState } from 'react'
import { CSpinner } from '@coreui/react'
import axios from 'axios'
import cookie from 'js-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import allActions from './actions/allActions'
import './scss/style.scss'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import socketIOClient from 'socket.io-client'
import io from 'socket.io-client'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))

const ltrTheme = createTheme({
  direction: 'ltr',
  palette: {
    primary: {
      main: '#D79F32',

      dark: '#180126',
    },
    secondary: {
      main: '#e3ebee',
    },
  },
})

const App = () => {
  const [notification, setNotification] = useState('')
  const token = cookie.get('token')
  const currentUser = useSelector((state) => state.currentUser)
  const dispatch = useDispatch()
  const [requestedUrl, setRequestedUrl] = useState('')

  useEffect(() => {
    const vaildateUser = async () => {
      const userDetails = await JSON.parse(localStorage.getItem('userDetails'))
      axios.defaults.headers.common['authorization'] = `Bearer ${token}`
      await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/admin/api/getProfile`, {
          params: {
            ...userDetails,
          },
        })
        .then((res) => {
          dispatch(allActions.userActions.setUser(res.data.data))
          // localStorage.setItem('profile_forms', JSON.stringify(res.data.profile_forms))
          // localStorage.setItem('profile_forms_codes', JSON.stringify(res.data.profile_forms_codes))

          // axios
          //   .get(`${process.env.REACT_APP_API_BASE_URL}/auth/admin/privileges`)
          //   .then((res) => {
          //     dispatch(allActions.userActions.setUserPrivileges(res.data))
          //   })
          //   .catch((error) => console.log(error.response))

          getNotifications()
        })
        .catch((err) => {
          axios.defaults.headers.common['authorization'] = ``
          localStorage.clear()
          cookie.remove('token')
          dispatch(allActions.userActions.logOut())
        })
    }
    if (token) {
      vaildateUser()
    } else {
      localStorage.clear()
    }
  }, [token])
  useEffect(() => {
    if (!requestedUrl) {
      setRequestedUrl(window.location.hash.substring(1))
    }
  }, [])

  const Loader = () => {
    return (
      <div className={'d-flex justify-content-center align-items-center h-100'}>
        <CSpinner color="primary" />
      </div>
    )
  }

  const handlePermissionRequest = () => {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.')
      } else if (permission === 'denied') {
        console.log('Notification permission denied.')
      } else {
        console.log('Notification permission dismissed.')
      }
    })
  }

  const displayNotification = (m) => {
    console.log('displayNotification')
    if (Notification.permission === 'granted') {
      new Notification('New Message!', {
        body: m,
      })
    }
  }

  const getNotifications = () => {
    axios(`${process.env.REACT_APP_API_BASE_URL}/admin/api/listNotifications`, {
      responseType: 'json',
    })
      .then(function (response) {
        const data = response.data.data

        const newArr = data.map((x) => ({
          from: x.from_id,
          message: x.message,
        }))
        localStorage.setItem('notifications', JSON.stringify(newArr))
        dispatch(allActions.userActions.setNotifications(newArr))
      })
      .catch(function (error) {})
  }

  useEffect(() => {
    handlePermissionRequest()
    const socket = io(process.env.REACT_APP_API_BASE_URL, {
      query: { user: 'admin' },
      withCredentials: true,
      transports: ['websocket', 'polling'],
    })

    socket.on('connect', () => {
      console.log('Connected to server')
    })

    socket.on('notification', (data) => {
      toast.success(data.message)
      displayNotification(data.message)
      setNotification(data.message)

      getNotifications()
    })

    socket.on('disconnect', () => {
      console.log('Disconnected from server')
    })

    return () => {
      socket.disconnect()
    }
  }, [])

  return (
    <ThemeProvider theme={ltrTheme}>
      <HashRouter>
        <React.Suspense fallback={loading}>
          <ToastContainer />
          <Routes>
            <Route
              path="*"
              name="Main"
              element={
                currentUser?.loggedIn === true ? <DefaultLayout /> : token ? <Loader /> : <Login />
              }
            />
            <Route path="/404" element={<Page404 />} />
          </Routes>
        </React.Suspense>
      </HashRouter>
    </ThemeProvider>
  )
}

export default App
